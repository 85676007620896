* {
  font-family: "poppins" !important;
}

body {
  height: auto !important;
}

.ant-layout-header {
  background-color: gainsboro !important;
}

.White {
  color: white !important;
}

.Bold {
  font-weight: 500px;
}

.ant-layout-sider.ant-layout-sider-dark {
  min-height: 750px !important;
}

.Black {
  color: black !important;
}

.skyblue {
  background-color: #ea9223 !important;
}

.ant-btn.ant-btn-primary {
  background-color: #ea9223 !important;
  color: white !important;
  border-color: #ea9223 !important;
}

.Radius_10 {
  border-radius: 10px !important;
}

.Radius_20 {
  border-radius: 20px !important;
}

.btnClass {
  border: 1px solid #ea9223 !important;
  color: #ea9223 !important;
}

.ant-menu {
  font-size: 15px !important;
}

.ant-menu-item-selected {
  background-color: #ea9223 !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: white !important;
}

.ant-menu-submenu-selected {
  color: black !important;
}

.ant-menu-item.active {
  background-color: #ea9223 !important;
}

.ant-menu-item.active a,
.ant-menu-item.active a:hover {
  color: white !important;
}

.ant-menu-item.ant-menu-item-only-child {
  color: black !important;
  padding-left: 70px !important;
}

.ant-menu-item.ant-menu-item-only-child.active {
  color: white !important;
  padding-left: 70px !important;
  border-right: 3px solid #ea9223;
}

.ant-menu-submenu.active {
  color: black !important;
}

.ant-layout-header {
  background-color: #ea9223 !important;
}

.ant-layout-sider.ant-layout-sider .profile span {
  width: 100px !important;
  height: 100px !important;
  line-height: 100px !important;
}

.ant-layout-sider.ant-layout-sider-collapsed .profile span {
  width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  margin-left: 0% !important;
}

.ant-menu-item li {
  list-style: none !important;
}

.anticon.trigger svg {
  width: 2em !important;
  height: 4em !important;
  color: white;
}

.anticon.anticon-setting svg {
  width: 2em !important;
  height: 4em !important;
  color: white;
}

.icons {
  margin-right: 10px !important;
  width: 18px !important;
  height: auto !important;
}

.icon {
  background-color: #ea9223 !important;
  border-radius: 50% !important;
  color: white !important;
}

.card {
  word-wrap: unset !important;
}

.badge {
  font-size: 100% !important;
  border-radius: 1.25rem !important;
  float: right !important;
  background-color: #ea9223 !important;
}

.ant-picker.ant-picker-range {
  border-radius: 20px !important;
  font-size: 28px !important;
}

.ant-table-row.ant-table-row-level-0 {
  background-color: white !important;
}

aside.ant-layout-sider.ant-layout-sider-dark {
  min-width: 18% !important;
  max-width: 18% !important;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
  min-width: 80px !important;
  max-width: 80px !important;
}

.ant-menu-item {
  height: auto !important;
  margin-bottom: 0px !important;
}

.ant-menu-inline {
  height: inherit;
}

.ant-menu-inline-collapsed .anticon {
  padding: 0 calc(50% - 16px / 2);
}

.ant-menu-inline-collapsed li {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.ant-menu-inline-collapsed .profile {
  padding: 0px;
  margin-top: 8px;
  margin-bottom: 18px;
}

.ant-menu-inline .profile {
  padding: 8px;
  margin-top: 20px;
}

.profile img {
  width: 50% !important;
  display: block;
  margin: auto;
}

.ant-menu-inline-collapsed .profile img {
  width: 90% !important;
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed
  .options
  span,
.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed
  .ant-menu-submenu-title
  span {
  display: none;
}

.ant-menu-item.ant-menu-item-only-child {
  padding-left: 13% !important;
}

.ant-menu-item.ant-menu-item-only-child.active {
  padding-left: 18% !important;
}

.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 18% !important;
}

.ant-menu-sub .ant-menu-item-only-child {
  padding-left: 15% !important;
}

.ant-menu-sub li {
  font-size: 100% !important;
}

.ant-layout-sider-collapsed .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}

.marginTop {
  margin-top: 150px !important;
}

.anticon.anticon-logout {
  width: 2em !important;
  height: 4rem !important;
  color: white !important;
}

.anticon.anticon-logout svg {
  height: auto;
  width: inherit;
}

.Radius_4 {
  border-radius: 4px !important;
}

.padding30px {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.font40 {
  font-size: 40px !important;
}

.font23 {
  font-size: 23px;
}

h1,
h2,
h3,
h4,
h5,
p {
  cursor: context-menu;
}

.mapHeight {
  height: 404px;
}

.min32 {
  min-height: 32px;
}

.ant-upload-list-item-error {
  border: 1px solid #d9d9d9 !important;
}

/* Dashboard */
.Dashboard .card-body h5 {
  min-height: 48px;
}

/* table */
th,
td {
  min-width: 120px;
  padding: 12px 12px;
}

.add-three-dots {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.combineButton {
  border-radius: 50px;
  background-color: white;
}
.form {
  background-color: #f9f9f9 !important;
}
.RadioToButton {
  list-style-type: none;
}
.RadioToButton input {
  display: none;
}
.RadioToButton label {
  border: none;
  border-radius: 50px;
  width: 100%;
  text-align: center;
}
.RadioToButton input:checked + label,
.Checked + label {
  background: #ea9223;
  color: white;
}
.RadioToButton {
  list-style-type: none;
}

.RadioToButton input {
  display: none;
}

.RadioToButton label {
  border: none;
  border-radius: 50px;
  width: 100%;
}

.RadioToButton input:checked + label,
.Checked + label {
  background: #ea9223;
  color: white;
}

@media only screen and (min-width: 992px) {
  .mapHeight {
    height: 360px;
  }
}

@media only screen and (max-width: 992px) {
  .ant-layout-sider {
    width: 25% !important;
  }

  .profile img {
    width: 75% !important;
  }

  .ant-menu-sub li {
    font-size: 90% !important;
  }
}

@media only screen and (max-width: 767px) {
  .ant-menu-submenu-title span {
    display: none;
  }

  .ant-layout-sider {
    width: 25% !important;
  }

  .profile img {
    width: 75% !important;
  }

  .options span {
    display: none;
  }

  .ant-menu-sub li {
    font-size: 80% !important;
  }
}

@media only screen and (max-width: 680px) {
  .ant-menu-sub li {
    font-size: 70% !important;
  }
}

@media only screen and (max-width: 500px) {
  .Trigger {
    display: none;
  }
}

@media only screen and (min-width: 500px) {
  .leftBottom {
    bottom: 0;
    position: absolute !important;
    right: 0;
  }
}

@media only screen and (min-width: 1200px) {
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
    min-width: 13% !important;
    max-width: 13% !important;
  }

  .ant-menu-submenu-title {
    width: 100%;
  }
}

@media only screen and (min-width: 767px) {
  .ant-menu-submenu .ant-menu-submenu-title {
    padding-left: 10% !important;
  }

  .ant-layout-sider-collapsed .ant-menu-submenu-title {
    padding: 0 calc(50% - 16px / 2) !important;
  }
}

.ant-table-content {
  max-width: 100% !important;
  overflow-x: auto !important;
}
